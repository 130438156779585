<template>
  <v-navigation-drawer>
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-list
          v-bind="props"
        >
          <v-list-item
            v-if="auth"
            prepend-avatar="https://randomuser.me/api/portraits/men/1.jpg"
            title="Welcome"
            :subtitle="auth.email"
          ></v-list-item>

          <v-dialog
            v-if="!auth"
            v-model="loginDialog"
            width="500"
          >
            <template v-slot:activator="{ props }">
              <v-list-item
                color="primary"
                v-bind="props"
              >Login</v-list-item>
            </template>
            <Login></Login>
          </v-dialog>

          <v-dialog
            v-if="!auth && !isProduction"
            v-model="signupDialog"
            width="500"
          >
            <template v-slot:activator="{ props }">
              <v-list-item
                color="primary"
                v-bind="props"
              >Signup</v-list-item>
            </template>
            <Signup></Signup>
          </v-dialog>

        </v-list>
      </template>

      <v-list v-if="auth">
        <v-list-item>
          <v-list-item-title><v-btn @click="_logout" block>Logout</v-btn></v-list-item-title>
        </v-list-item>
      </v-list>

    </v-menu>


    <v-divider></v-divider>

    <v-list density="compact" nav v-if="isLoggedIn">
      <v-list-item to="/" prepend-icon="mdi-folder" title="/" value="/"></v-list-item>
      <v-list-item v-if="isAdmin" to="/collections" prepend-icon="mdi-cards-outline" title="Collections"></v-list-item>
    </v-list>

    <hr />

    <v-list density="compact" nav v-if="isAdmin">
      <v-list-item to="/admin" prepend-icon="mdi-security" title="Admin"></v-list-item>
      <v-list-item :to="{name: 'admin-collection-list'}" prepend-icon="mdi-cards" title="Collections"></v-list-item>
      <v-list-item :to="{name: 'admin-crawler-list'}" prepend-icon="mdi-cloud-print" title="Crawlers"></v-list-item>
      <v-list-item :to="{name: 'admin-mapper-list'}" prepend-icon="mdi-database-refresh" title="Mappers"></v-list-item>
      <v-list-item :to="{name: 'admin-changeset-list'}" prepend-icon="mdi-database-refresh" title="Changesets"></v-list-item>
      <v-list-item :to="{name: 'admin-bulk-action-list'}" prepend-icon="mdi-animation-play" title="Bulk Actions"></v-list-item>
      <v-list-item :to="{name: 'admin-dynamic-image-list'}" prepend-icon="mdi-image-area" title="Dynamic Images"></v-list-item>
      <v-list-item :to="{name: 'admin-export-list'}" prepend-icon="mdi-database-export" title="Exports"></v-list-item>
      <v-list-item :to="{name: 'admin-user-list'}" prepend-icon="mdi-account-group" title="Users"></v-list-item>
      <v-list-item :to="{name: 'admin-scripts'}" prepend-icon="mdi-console" title="Scripts"></v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>

<!-- Registration Form -->
<!--      <div>-->
<!--        <h3>Register</h3>-->
<!--        <input v-model="registerEmail" placeholder="Email" />-->
<!--        <input v-model="registerPassword" placeholder="Password" type="password" />-->
<!--        <button @click="register">Register</button>-->
<!--      </div>-->
<script>
import {mapActions, mapState} from "vuex";
import supabase from "../supabase";
import Login from "./admin/Login.vue";
import Signup from "./admin/Signup.vue";

export default {
  components: {
    Login,
    Signup,
  },
  data() {
    return {
      loginDialog: false,
      signupDialog: false,
    };
  },
  computed: {
    ...mapState(['auth', 'isProduction']),
    isLoggedIn() {
      return this.auth !== null;
    },
    isAdmin() {
      return this.auth !== null;
    },
  },
  methods: {
    ...mapActions(['setAuth', 'logout']),
    _logout() {
      supabase.auth.signOut()
      this.logout()
    },
    async register() {
      const { user, error } = await supabase.auth.signUp({
        email: this.registerEmail,
        password: this.registerPassword,
      })

      if (error) {
        console.error('Error registering:', error);
      } else {
        this.setAuth(user);
      }
    },
  },
}
</script>
