import axios from 'axios'
import store from './store'

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : `http://localhost:8080/api`,
  headers: {
    'Content-type': 'application/json',
  },
})

function handleAuthResponse(response) {
  if(!response?.headers) {
    return
  }
  const authHeader = response.headers['g_auth']
  const logoutHeader = response.headers['g_logout']

  if(logoutHeader === '1') {
    console.log('logout triggered')
    store.dispatch('logout')
    // window.location.href = '/'
  }
  if(authHeader) {
    const authHeaderObj = JSON.parse(authHeader)
    store.dispatch('setAuth', authHeaderObj)
  }
}

axiosInstance.interceptors.response.use(function (response) {
  handleAuthResponse(response)
  return response;
}, function (error) {
  handleAuthResponse(error.response)
  return Promise.reject(error)
});

function getAuth() {
  const auth = localStorage.getItem('auth');
  if (auth) {
    return JSON.parse(auth);
  }
  return null
}

axiosInstance.interceptors.request.use(
  async (config) => {
    const auth = getAuth();
    if (auth) {
      config.headers['Authorization'] = `Bearer ${auth.access_token}`;
      config.headers['token_id'] = `${auth.token_id}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance
