import http from '../http-common'

class UserDataService {

  async create (data) {
    const response = await http.post('/user', data);
    return response.data;
  }

  async findAll () {
    const response = await http.get('/user');
    return response.data;
  }

  async findOne (id) {
    const response = await http.get(`/user/${id}`);
    return response.data;
  }

  async remove (id) {
    const response = await http.delete(`/user/${id}`);
    return response.data;
  }

  async login(data) {
    const response = await http.post('/user/login', data);
    return response.data;
  }

  async signup(data) {
    const response = await http.post('/user/signup', data);
    return response.data;
  }

}

export default new UserDataService()
